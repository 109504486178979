<template>
  <div>
    <div id="privacy-policy-page" class="flex-column">
      <div class="flex-center width-1000 body-2">
        <div>
          <h1 class="h1-title">Privacy policy</h1>
        </div>

        <div>
          <p>Effective date: November 19, 2018</p>
          <p>
            iAppraise Australia Pty Ltd (ABN 78 628 530 594) (trading as “iAppraise”) ("us", "we", or "our") respects
            your right to privacy and will take all reasonable steps to protect personal information disclosed to
            Appraise in accordance with the Privacy Act 1988 (Cth) (“Privacy Act”).
          </p>

          <p>
            iAppraise operates the iAppraise website, mobile application, and associated products and services
            (collectively, the "Services").
          </p>

          <p>
            This page informs you of our policies regarding the collection, use, and disclosure of Personal Information
            when you use our Service and the choices you have associated with that data..
          </p>

          <p>
            By using the Service, you agree to the collection and use of Personal Information in accordance with this
            policy.
          </p>

          <p>
            Unless otherwise defined in this Privacy Policy, terms used in this Privacy Policy have the same meanings as
            in our Terms and Conditions.
          </p>

          <p>
            For the purposes of this Privacy Policy, “Personal Information” has the meaning provided in the Privacy Act
            1988 (Cth) (“Privacy Act”). For reference, Personal Information means information or an opinion about an
            identified individual, or an individual who is reasonably identifiable:
          </p>
          <ol>
            <li>whether the information or opinion is true or not; and</li>
            <li>whether the information or opinion is recorded in a material form or not.</li>
          </ol>

          <h3>Collection And Use of Personal Information</h3>

          <p>
            We will, from time to time, collect and store personal information in a variety of ways, including (but not
            limited to): when you disclose the personal information to us, when you interact with us electronically or
            in person, when you access our website or app, when we engage in business with you (including sale and
            receipt of products and services), or when personal information is provided to us by third parties.
          </p>

          <h4>Personal Information</h4>
          <p>
            While using our Service, we may ask you to provide us with certain personally identifiable information,
            including, but not limited to:
          </p>
          <ul>
            <li>Email address</li>
            <li>First name and last name</li>
            <li>Postal Address</li>
            <li>Phone number</li>
            <li>Banking and credit card details</li>
          </ul>
          <p>
            By providing us with information, you consent to the collection, storage and use of that information subject
            to the terms of this Privacy Policy.
          </p>
          <p>
            If you withhold your personal information, it may not be possible for us to interact or engage in business
            with you.
          </p>

          <h3>Use of Data</h3>

          <p>iAppraise Pty Ltd uses the collected data for various purposes:</p>
          <ul>
            <li>To provide you with the Services</li>
            <li>To provide you with information about our Services, or third parties' products and services</li>
            <li>To manage, research and develop our Services</li>
            <li>To notify you about changes to our Services</li>
            <li>To allow you to participate in interactive features of our Services when you choose to do so</li>
            <li>To provide customer care and support</li>
            <li>To provide analysis or valuable information so that we can improve the Services</li>
            <li>To monitor the usage of the Services</li>
            <li>To detect, prevent and address technical issues</li>
            <li>
              To communicate with you by a variety of measures including (but not limited to): telephone, email, social
              media, sms or mail
            </li>
            <li>To investigate any complaints</li>
            <li>To fulfil any State or Commonwealth bodies' requests for information or reporting;</li>
            <li>To otherwise administer our business</li>
          </ul>

          <h3>Disclosure of Data</h3>
          <p>
            iAppraise may disclose your personal information to any employees, officers, insurers, professional
            advisers, agents, suppliers or subcontractors of iAppraise or any of its related bodies corporate (as
            defined in the Corporations Act 2001 (Cth), insofar as reasonably necessary for the purposes set out in this
            Privacy Policy.
          </p>

          <p>
            If we do disclose your personal information to a third party, we will take all reasonable steps to protect
            it in accordance with this Privacy Policy.
          </p>

          <h3>Cross Border Disclosure</h3>
          <p>
            Your information, including Personal Information, may be transferred to — and maintained on — computers
            located outside of your state, province, country or other governmental jurisdiction where the data
            protection laws may differ than those from your jurisdiction.
          </p>
          <p>
            If you are located outside Australia and choose to provide information to us, please note that we transfer
            the data, including Personal Information, to Australia and process it there.
          </p>
          <p>
            Your consent to this Privacy Policy followed by your submission of such information represents your
            agreement to that transfer.
          </p>
          <p>
            iAppraise Pty Ltd will take all steps reasonably necessary to ensure that your data is treated securely and
            in accordance with this Privacy Policy and no transfer of your Personal Information will take place to an
            organization or a country unless there are adequate controls in place including the security of your data
            and other personal information.
          </p>

          <h3>Access to Personal Information</h3>
          <p>
            You may request details of Personal Information that we hold about you in accordance with the Privacy Act.
          </p>
          <p>Please contact us if you would like a copy of the Personal Information which we hold about you.</p>
          <p>
            We reserve the right to refuse to provide you with Personal Information that we hold about you, in certain
            circumstances set out in the Privacy Act or any other applicable law.
          </p>
          <p>
            It is important that the Personal Information we hold about you is accurate, complete and up-to-date in
            order to effectively engage you in business. If you find that Personal Information we hold about you is
            incorrect, inaccurate, out-of-date, incomplete, irrelevant or misleading, you can ask us to correct it by
            contacting us.
          </p>

          <h3>Legal Requirements</h3>
          <p>
            iAppraise Pty Ltd may disclose your Personal Information in the good faith belief that such action is
            necessary to:
          </p>
          <ul>
            <li>To comply with a legal obligation</li>
            <li>To protect and defend the rights or property of iAppraise Pty Ltd</li>
            <li>To prevent or investigate possible wrongdoing in connection with the Service</li>
            <li>To protect the personal safety of users of the Service or the public</li>
            <li>To protect against legal liability</li>
          </ul>

          <h3>Security Of Data</h3>
          <p>
            We are committed to ensuring that the personal information you provide to us is secure. In order to prevent
            unauthorised access or disclosure, we have put in place suitable physical, electronic and managerial
            procedures to safeguard and secure information and protect it from misuse, interference, loss and
            unauthorised access, modification and disclosure.
          </p>
          <p>
            The security of your data is important to us, but remember that no method of transmission over the Internet,
            or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to
            protect your Personal Information, we cannot guarantee its absolute security.
          </p>

          <h3>Third-Party Service Providers</h3>
          <p>
            We may employ third party companies and individuals to facilitate our Service ("Service Providers"), to
            provide the Service on our behalf, to perform Service-related services or to assist us in analysing how our
            Service is used.
          </p>
          <p>
            These third parties have access to your Personal Information only to perform these tasks on our behalf and
            are obligated not to disclose or use it for any other purpose.
          </p>

          <h3>Links To Other Sites</h3>

          <p>
            Our Service may contain links to other sites that are not operated by us. If you click on a third party
            link, you will be directed to that third party's site. We have no control over and assume no responsibility
            for the content, privacy policies or practices of any third party sites or services.
          </p>

          <p>We strongly advise you to review the Privacy Policy of every site you visit.</p>

          <h3>Direct marketing</h3>
          <p>
            We may deliver direct marketing communications to you about our products and services, and any other
            products or services that you might be interested in.
          </p>
          <p>
            We may provide your Personal Information to third parties for the purpose of direct marketing communications
            to you by those third parties about products and services that you might be interested in.
          </p>
          <p>
            If you notify us that you do not want to receive these communications, we will comply with your instruction
            and will not use your Personal Information for such purposes.
          </p>
          <p>
            Subject to any other term of this Privacy Policy, we will only use or disclose your Personal Information for
            the purposes of direct marketing if:
          </p>
          <ol>
            <li>
              Where we collected the information from you:
              <ol>
                <li>
                  it is reasonable in the circumstances to expect that we would use or disclose the information for
                  direct marketing purposes;
                </li>
                <li>we provide you with a simple means to 'opt-out' of direct marketing communications from us; and</li>
                <li>you have not elected to 'opt-out' from receiving such direct marketing communications from us.</li>
              </ol>
            </li>
            <li>
              Where we collected the information from a third party:
              <ol>
                <li>
                  we have your consent to use or disclosure of your Personal Information for direct marketing purposes,
                  or it is impracticable to obtain that consent; and
                </li>
                <li>we provide you with a simple means to 'opt-out' of direct marketing communications from us; and</li>
                <li>you have not elected to 'opt-out' from receiving such direct marketing communications from us.</li>
              </ol>
            </li>
          </ol>

          <p>You may opt-out of receiving direct marketing communications by:</p>
          <ol>
            <li>checking the relevant box on the form used to collect your Personal Information;</li>
            <li>clicking a link on the email communication sent to you; or</li>
            <li>contacting us using the contact details provided in this Privacy Policy.</li>
          </ol>

          <h3>Privacy of Minors</h3>
          <p>Our Service does not address anyone under the age of 18 ("Children").</p>
          <p>
            We do not knowingly collect personally identifiable information from anyone under the age of 18. If you are
            a parent or guardian and you are aware that your Children has provided us with Personal Information, please
            contact us. If we become aware that we have collected Personal Information from children without
            verification of parental consent, we take steps to remove that information from our servers.
          </p>
          <h3>Usage Data</h3>
          <p>
            When you access the Services, we may collect certain information automatically, including, but not limited
            to, the type of device you use, your device unique ID, the IP address of your device, your operating system,
            the type of Internet browser you use, unique device identifiers, the website or apps visited immediately
            before accessing the Services, and other diagnostic data ("Usage Data").
          </p>

          <h3>Tracking And Cookies Data</h3>
          <p>
            We may from time to time use cookies and similar tracking technologies to track the activity on our Services
            and hold certain information.
          </p>
          <p>
            Cookies are files with small amount of data which may include an anonymous unique identifier. Cookies are
            sent to your browser from a website and stored on your device. Tracking technologies also used are beacons,
            tags, and scripts to collect and track information and to improve and analyse our Service.
          </p>
          <p>
            You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if
            you do not accept cookies, you may not be able to use some portions of our Service.
          </p>
          <p>Examples of Cookies we use:</p>
          <ul>
            <li>Session Cookies. We use Session Cookies to operate our Service.</li>
            <li>Preference Cookies. We use Preference Cookies to remember your preferences and various settings.</li>
            <li>Security Cookies. We use Security Cookies for security purposes.</li>
          </ul>
          <p>
            When you use the application, we may use GPS technology to determine your current location to determine the
            city you are located within and display relevant advertisements.
          </p>

          <h3>Complaints about Privacy</h3>
          <p>
            If you have any complaints about our privacy practices, please feel free contact us. We take complaints very
            seriously and will respond shortly after receiving written notice of your complaint. Our contact detail is
            provided below.
          </p>

          <h3>Notifiable Data Breaches</h3>
          <p>
            iAppraise will notify individuals whose personal information is involved in a data breach that is likely to
            result in serious harm. This notification will include recommendations about the steps individuals should
            take in response to the breach. The Australian Information Commissioner will also be notified of such data
            breaches.
          </p>
          <p>If you believe there has been a data breach, you should contact us as soon as possible.</p>

          <h3>Changes To This Privacy Policy</h3>
          <p>
            We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new
            Privacy Policy on this page.
          </p>
          <p>
            We will let you know via email and/or a prominent notice on our Service, prior to the change becoming
            effective and update the "effective date" at the top of this Privacy Policy.
          </p>
          <p>
            You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy
            are effective when they are posted on this page.
          </p>

          <h3>Contact Us</h3>
          <p>If you have any questions about this Privacy Policy, please contact us:</p>
          <ul>
            <li>By email: support@iappriase.net.au</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PrivacyPolicy"
};
</script>

<style lang="scss" scoped>
@import "@/scss/v2/base.scss";
@import "@/scss/v2/legal.scss";

#privacy-policy-page {
  padding-top: $PADDING_X2_05;
}
</style>
